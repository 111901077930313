import { render, staticRenderFns } from "./AnnullaQuotazione.vue?vue&type=template&id=0f266164&"
import script from "./AnnullaQuotazione.vue?vue&type=script&lang=js&"
export * from "./AnnullaQuotazione.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QInput from 'quasar/src/components/input/QInput.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QList,QItem,QItemSection,QItemLabel,QInput});
/* hot reload */
if (module.hot) {
  var api = require("/builds/navert/abynext-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0f266164')) {
      api.createRecord('0f266164', component.options)
    } else {
      api.reload('0f266164', component.options)
    }
    module.hot.accept("./AnnullaQuotazione.vue?vue&type=template&id=0f266164&", function () {
      api.rerender('0f266164', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/PiattaformaRami/Quotazioni/AnnullaQuotazione.vue"
export default component.exports